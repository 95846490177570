<template>
  <b-overlay
    :opacity="0.5"
    :show="loading"
    rounded="sm"
    :blur="'true'"
  >
    <div class="auth-wrapper auth-v1 px-2">
      <div class="auth-inner py-2">

        <!-- Login v1 -->
        <BCard class="mb-0">
          <BLink class="brand-logo">
            <curfox-logo />

            <h2 class="brand-text text-primary ml-1">
              Curfox
            </h2>
          </BLink>

          <BCardTitle class="mb-1">
            Welcome to Curfox! 👋
          </BCardTitle>
          <BCardText class="mb-2">
            Please enter email and password to your account and start the registration
          </BCardText>

          <!-- form -->
          <ValidationObserver
            ref="loginForm"
            #default="{invalid}"
          >
            <BForm
              class="auth-login-form mt-2"
              @submit.prevent="onClickCreateMerchant"
            >

              <!-- email -->
              <BFormGroup
                label-for="email"
                label="Email"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <BFormInput
                    id="email"
                    v-model="userEmail"
                    name="login-email"
                    :state="errors.length > 0 ? false:null"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] || emailErrorMessage }}</small>
                </ValidationProvider>
              </BFormGroup>

              <!-- password -->
              <BFormGroup>
                <div class="d-flex justify-content-between">
                  <label for="password">Password</label>
                </div>
                <ValidationProvider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <BInputGroup
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <BFormInput
                      id="password"
                      v-model="password"
                      :type="passwordFieldType"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false:null"
                      name="login-password"
                      placeholder="********"
                    />

                    <BInputGroupAppend is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </BInputGroupAppend>
                  </BInputGroup>
                  <small class="text-danger">{{ errors[0] || passwordErrorMessage }}</small>
                </ValidationProvider>
              </BFormGroup>
              <!-- submit button -->
              <BButton
                variant="primary"
                type="submit"
                block
                :disabled="invalid||invalidls||emailExceedsLimit"
              >
                NEXT
              </BButton>
            </BForm>
          </ValidationObserver>

          <BCardText class="text-center mt-2">
            <span>Already have an account? </span>
            <BLink :to="{path:'/login'}">
              <span>Login</span>
            </BLink>
          </BCardText>

          <div class="divider my-2">
            <div class="divider-text">
              or
            </div>
          </div>

          <!-- social button -->
          <div class="auth-footer-btn d-flex justify-content-center">
            <GoogleSignUp />
          </div>
        </BCard>
        <!-- /Login v1 -->
      </div>
    </div>
  </b-overlay>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardTitle, BCardText, BInputGroup, BInputGroupAppend, BOverlay,
} from 'bootstrap-vue'
import CurfoxLogo from '@core/layouts/components/Logo.vue'
import GoogleSignUp from '@/components/GoogleSignUp.vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const RegisterRepository = RepositoryFactory.get('register')

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    CurfoxLogo,
    BCardText,
    BOverlay,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    GoogleSignUp,
  },
  mixins: [togglePasswordVisibility, SuccessMessage, ErrorMessage],
  data() {
    return {
      userEmail: '',
      password: '',
      provider: 'regular',
      status: '',
      // validation rules
      required,
      email,
      loading: false,
      passwordErrorMessage: '',
      emailErrorMessage: '',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    invalidls() {
      const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,15}$/
      return !regex.test(this.password)
    },
    emailExceedsLimit() {
      return this.userEmail.length > 50
    },
  },
  watch: {
    password() {
      this.validatePasswordStrength()
    },
    userEmail() {
      this.validateEmailMaxLength()
    },
  },
  methods: {
    validatePasswordStrength() { // validate password
      const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,15}$/
      if (!regex.test(this.password)) {
        this.passwordErrorMessage = 'Password must contain at least one uppercase letter, one lowercase letter, one number, and be between 8 and 15 characters long'
      } else {
        this.passwordErrorMessage = ''
      }
    },
    validateEmailMaxLength() {
      if (this.userEmail.length > 50) { // validate email
        this.emailErrorMessage = 'Email must not exceeded the 50 characters.'
      } else {
        this.emailErrorMessage = ''
      }
    },
    async onClickCreateMerchant() {
      this.Loading = true
      try {
        const payload = {
          email: this.userEmail,
          password: this.password,
          provider: this.provider,
        }
        const res = await RegisterRepository.createBusinessUser(payload)
        if (res.status === 200) {
          // Email is already registered, go to login page
          this.showSuccessMessage(res.data.message)
          const emailVerify = res.data.isVerified
          const businessVerify = res.data.hasBusiness
          // email not verified handle
          if (!emailVerify) {
            localStorage.setItem('email', this.userEmail)
            this.$router.push('/verify')
            // busness not verified handle
          } else if (emailVerify && !businessVerify) {
            localStorage.setItem('email', this.userEmail)
            this.$router.push('/buisness-details')
            // email and business registered acccount handle
          } else if (emailVerify && businessVerify) {
            this.showSuccessMessage('account already registered, please login to system')
            const redirecturl = `${res.data.domain}/login?email=${this.payload.email}&token=${res.data.token}`
            window.location.href = redirecturl
          }
        } else if (res.status === 201) {
          localStorage.setItem('email', this.userEmail)
          this.showSuccessMessage(res.data.message)
          this.$router.push('/verify')
        } else {
          this.showErrorMessage(res.data.errors)
        }
      } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
          this.showErrorMessage(error.response.data.message)
        } else {
          this.showErrorMessage('An error occurred while email registering.')
        }
      }
      this.Loading = false
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
